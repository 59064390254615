import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Skeleton} from 'antd';
import {StaticImage} from 'gatsby-plugin-image';
import ReactDelighters from 'rev.sdk.js/Components/ReactDelighters';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import ReactPlayer from 'react-player';
import Slick from 'react-slick';
import {useOutlet} from 'reconnect.js';
import moment from 'moment';
import tracker from 'rev.sdk.js/Utils/Tracker';

import InfoSections from '../../Components/InfoSections';
import ContactSection from '../../Components/ContactSection';
import DeckSection from '../../Components/DeckSection';

function Landing(props) {
  function scrollTo(sectionId, evt) {
    evt.preventDefault();
    const elem = document.getElementById(sectionId);
    if (elem) {
      const top = elem.offsetTop;
      window.scrollTo({top: top - 60, behavior: 'smooth'});
    }
  }

  return (
    <Wrapper>
      <Banner>
        <div className="content">
          <div
            style={{
              flex: 1,
              minWidth: 150,
              maxWidth: 750,
              paddingTop: '10%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
            <img
              src="/banner-logo.png"
              style={{width: '100%', maxWidth: 700}}
            />
            <h1
              style={{
                fontFamily: 'Futura',
                marginTop: 40,
                fontWeight: 'bold',
                fontSize: '2em',
                color: 'white',
              }}>
              Bring digital to your reality
            </h1>
            <img
              src="/banner-down.png"
              width="60"
              style={{marginTop: 60, alignSelf: 'center', cursor: 'pointer'}}
              onClick={scrollTo.bind(null, 'intro')}
            />
          </div>

          <div style={{flex: 1, minWidth: 250, maxWidth: 450, padding: 30}}>
            <img src="/banner-phone.webp" style={{width: '100%'}} />
          </div>
        </div>
      </Banner>

      <DeckSection />

      <InfoSections />

      <ContactSection />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
`;

const Banner = styled.div`
  display: flex;
  flex-direction: 'row';
  justify-content: center;
  align-items: center;

  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: min(50px, 10%);
  padding-left: min(50px, 10%);

  background: linear-gradient(#00fbbb, white);

  width: 100vw;

  & .content {
    width: 100%;
    max-width: 1200px;

    display: flex;
    flex-direction: 'row';
    justify-content: center;
    align-items: stretch;

    flex-wrap: wrap;
  }
`;

export default Landing;
